import * as React from "react"
import Facebook from "../icons/facebook"
import Instagram from "../icons/instagram"
import YouTube from "../icons/youtube"

export default function Substack(){
  return (
    <div className="substack">
      <div className="h-wrap"><h2>Follow Nibs</h2></div>
      <div className="substack__iframe">
        <iframe src="https://nibs.substack.com/embed" width="480" height="320" frameBorder="0" scrolling="no" />
      </div>
      <div className="substack__social">
        <a title="Instagram" href="https://www.instagram.com/nibsvanderspuy/" target={"_blank"}><Instagram /></a>
        <a title="Facebook" href="https://www.facebook.com/nibsvanderspuy" target={"_blank"}><Facebook /></a>
        <a title="YouTube" href="https://www.youtube.com/user/NibsVanDerSpuy/videos" target={"_blank"}><YouTube /></a>
      </div>
    </div>
  )
}
