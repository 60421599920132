import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Tour from "./tour"

export default function Hero(){
  const [loaded, setLoaded] = React.useState('')
  return (
    <div className="hero">
      <StaticImage src="../images/nibs.jpg" alt="Nibs van der Spuy" className="hero__bg" />
      <div className="hero__logo">
        <img src="/images/logo.png" alt="Nibs van der Spuy" />
      </div>
      <div className={`hero__tour ${loaded}`}>
        <Tour onComplete={() => {
          setLoaded('hero__tour--loaded')
        }} />
      </div>
    </div>
  )
}
