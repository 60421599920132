import * as React from "react"
import fetch from 'node-fetch'
import { format, parse } from 'date-fns'

const startOfDay = new Date()
startOfDay.setUTCHours(-2,0,0,0)

console.log(startOfDay)

export type Show = {
  date: string
  time: string
  info: string
  place: string
  bookings: string
  tickets: string
  link: string
  future: boolean
}

function isTsv( body: string ){
  const line1 = body.split("\n")[0]
  return line1 && line1.split("\t").length > 2;
}

export default function Tour( {onComplete = () => {} }){
  const [ showsFuture, setShowsFuture ] = React.useState<Show[]>( [] )
  const [ showsPast, setShowsPast ] = React.useState<Show[]>( [] )

  React.useEffect(() => {

    if( isTsv(sessionStorage.getItem('tour') || '') ){
      tour(sessionStorage.getItem('tour') as string)
      onComplete()
      return
    }

    fetch('https://nibsmusic.netlify.app/.netlify/functions/tour').then( res => {
      return res.text();
    }).then( body => {
      if( isTsv(body) ){
        window.sessionStorage && sessionStorage.setItem('tour', body)
        tour(body)
        onComplete()
      }
    })

    function tour( body: string ){
      const [_headings, ...rest] = body.split("\n")
      const shows = rest.map( line => line.split("\t")).map( (
        [ date, time, info, place, link, bookings, tickets ]) => ({
        date: format(new Date(date), 'EEEE dd MMMM yyyy'),
        time,
        info,
        place,
        link,
        bookings,
        tickets,
        future: new Date(date) >= startOfDay,
      }))

      setShowsFuture(shows.filter( e => e.future ))
      setShowsPast(shows.filter( e => !e.future ))
    }
  }, [])

  const compact = showsFuture.length > 3 || showsPast.length > 3
  const [ showAll, setShowAll ] = React.useState(false)

  return (
    <div className="tour">
      <div className="h-wrap"><h2>Tour</h2></div>
      <div className={`tour__dates ${compact && !showAll ? 'tour__dates--compact' : '' }`}>
        { showsFuture.map( e => {
          return <Show {...e} key={e.date + e.time} />
        })}
        { showsFuture.length === 0 && showsPast.length > 0 ? <h4>No future tour dates right now. Recent shows:</h4> : null }
        { showsFuture.length === 0 && showsPast.filter(( (e, i) => i < 1 )).map( e => {
          return <Show {...e} />
        })}

        { compact && <a href="#" className="tour__dates-more" onClick={e => { e.preventDefault(); setShowAll(true); scrollHalf() }}>Show all</a>}
      </div>
    </div>
  )
}

function scrollHalf(){
  window.scroll({
    behavior: 'smooth',
    top: window.scrollY + (window.innerHeight / 3)
  })
}

function Show( e: Show){
  return (
    <div className={`tour__dates-row tour__dates-row-${ e.future ? 'future' : 'past' }`}>
      <div className="tour__dates-date">
        { e.date }{ e.time ? ', ' + e.time : ''}
      </div>
      { e.info &&
        <div className="tour__dates-info">
          {e.info}
        </div>
      }
      <div className="tour__dates-place">
        { e.link && <a href={e.link} target="_blank">{e.place}</a>}
        { !e.link && e.place }
      </div>
      <div className="tour__dates-contact">
        { e.bookings }
      </div>
      { e.link &&
        <div className="tour__dates-link">
          <a href={e.link} target="_blank">Buy Tickets</a>
        </div>
      }
    </div>
  )
}
