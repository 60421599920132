import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { discs } from './discography'

export default function Latest(){
  return (
    <div className="latest">
      <div className="latest__cover">
        <StaticImage src="../images/maiden.jpg" alt="Maiden of the River" />
        <div className="h-wrap"><h2>Latest Release</h2></div>
        <h3>“Maiden of the River”</h3>
      </div>

      <div className="latest__body">
        <p>It had been a long time since I had written and made a record, virtually seven years. It was very unusual for me, as I had released ten albums between 2000 and 2013. These included seven solo and three collaboration albums. I guess for everybody life really gets busy, and one spends so much time taking care of the business to enable the things we love the most, like performing to become a reality...</p>
        <div className="latest__body__links">
          <a href="https://nibs.substack.com" target="_blank">Continue Reading</a>
          <span></span>
          <a href={discs[0].link} target="_blank">Buy the Album</a>
        </div>
      </div>
    </div>
  )
}
