import * as React from "react"
import Hero from "../sections/hero"
import '../scss/index.scss'
import Latest from "../sections/latest"
import Video from "../sections/video"
import Substack from "../sections/substack"
import Discography from "../sections/discography"
import Footer from "../sections/footer"
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from "gatsby"

// markup
const IndexPage = () => {
  const { site } = useStaticQuery(graphql`
    query MetaQuery {
      site {
        siteMetadata {
          title
          siteUrl
          description
        }
      }
    }
  `)

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />

        <title>Nibs van der Spuy</title>
        <link rel="canonical" href={site.siteMetadata.siteUrl} />

        <meta name="title" content="Nibs van der Spuy" />
        <meta name="description" content="Official website of South African folk musician Nibs van der Spuy with new album 'Maiden of the River'." />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={site.siteMetadata.siteUrl} />
        <meta property="og:title" content="Nibs van der Spuy" />
        <meta property="og:description" content="Official website of South African folk musician Nibs van der Spuy with new album 'Maiden of the River'." />
        <meta property="og:image" content={site.siteMetadata.siteUrl + '/meta.png'} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={site.siteMetadata.siteUrl} />
        <meta property="twitter:title" content="Nibs van der Spuy" />
        <meta property="twitter:description" content="Official website of South African folk musician Nibs van der Spuy with new album 'Maiden of the River" />
        <meta property="twitter:image" content={site.siteMetadata.siteUrl + '/meta.png'} />

      </Helmet>
      <Hero />
      <Video />
      <Latest />
      <Substack />
      <Discography />
      <Footer />
    </main>
  )
}

export default IndexPage
