import * as React from "react"

export default function Footer(){
  return (
    <footer className="footer">
      <a className="footer__link" href="https://nibs.substack.com/p/maiden-of-the-river?showWelcome=true" target="_blank">Subscribe now</a>
      <p>Keep up-to-date on tour dates &amp; new releases.</p>
      <span>&copy; 2022 Nibs van der Spuy. All rights reserved.</span>
    </footer>
  )
}
