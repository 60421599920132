import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const discs = [
  {
    src: '/images/albums/00 - maiden.jpg',
    title: 'Maiden of the River',
    link: 'https://nibsvanderspuy.bandcamp.com/album/maiden-of-the-river'
  },
  {
    src: '/images/albums/01 - circle.jpeg',
    title: 'A Circle of Swallows',
    link: 'https://nibsvanderspuy.bandcamp.com/album/a-circle-of-swallows'
  },
  {
    src: '/images/albums/02 - live.jpeg',
    title: 'Live in Lisbon with Guy Buttery',
    link: 'https://guybuttery.bandcamp.com/album/live-in-lisbon'
  },
  {
    src: '/images/albums/03 - natalia.jpeg',
    title: 'Natalia',
    link: 'https://nibsvanderspuy.bandcamp.com/album/natalia'
  },
  {
    src: '/images/albums/04 - house.jpeg',
    title: 'A House Across the River',
    link: 'https://nibsvanderspuy.bandcamp.com/album/a-house-across-the-river'
  },
  {
    src: '/images/albums/05 - bird.jpeg',
    title: 'A Bird in the Hand',
    link: 'https://nibsvanderspuy.bandcamp.com/album/a-bird-in-the-hand'
  },
  {
    src: '/images/albums/06 - feet.jpeg',
    title: 'Beautiful Feet',
    link: 'https://nibsvanderspuy.bandcamp.com/album/beautiful-feet'
  },
  {
    src: '/images/albums/07 - borders.jpeg',
    title: 'Crossing Borders, Driving North',
    link: 'https://nibsvanderspuy.bandcamp.com/album/crossing-borders-driving-north-deluxe-2cd-anthology'
  },
  {
    src: '/images/albums/08 - morning.jpeg',
    title: 'Morning Star',
    link: 'https://nibsvanderspuy.bandcamp.com/album/morning-star'
  }
]

export default function Discography(){
  return (
    <div className="discography">
      <div className="h-wrap">
        <h2>Discography</h2>
      </div>
      <div className="discography__titles">
        { discs.map( ( {src, title, link }) => {
          return (
            <div className="discography__title" key={title}>
              <a href={ link } target="_blank" className="discography__cover-link">
                <img src={ src } alt={ title } className="discography__image" />
              </a>
              <a href={ link } target="_blank" className="discography__link">Listen / Buy</a>
            </div>
          )
        })}
      </div>
    </div>
  )
}
